<template>
  <table-view
    ref="tableView"
    url="/car/break/regulations/listForPage"
    :filterable="false"
    :filter-form="dataForm"
    :headers="headers"
    show-index
    selectable
    single
    :addable="isAuthed('car_safe_add')"
    :editable="isAuthed('car_safe_update')"
  >
    <el-form slot="search" @keyup.enter.native="query" inline>
      <el-input clearable v-model="dataForm.number" placeholder="自编号"></el-input>
      <el-select v-model="dataForm.companyId" clearable filterable placeholder="事故状态">
        <el-option v-for="item in companyList" :key="item.id" :label="item.companyName" :value="item.id"></el-option>
      </el-select>
      <el-select v-model="dataForm.companyId" clearable filterable placeholder="责任人">
        <el-option v-for="item in companyList" :key="item.id" :label="item.companyName" :value="item.id"></el-option>
      </el-select>
      <el-form-item label="事故时间">
        <el-date-picker clearable value-format="timestamp" v-model="dataForm.startTime"></el-date-picker>
        <label>到</label>
        <el-date-picker clearable value-format="timestamp" v-model="dataForm.endTime"></el-date-picker>
      </el-form-item>
      <el-button @click="query" type="primary">查询</el-button>
    </el-form>
  </table-view>
</template>

<script>
import TableView from '@/components/templates/table-view'

export default {
  name: 'car-violation',
  components: { TableView },
  data () {
    return {
      headers: [
        { id: 1, prop: 'number', label: '自编号', width: 150 },
        { id: 2, prop: 'licensePlate', label: '车牌号码', width: 120 },
        { id: 3, prop: 'carSize', label: '车辆型号', width: 100 },
        { id: 4, prop: 'regulationsStatus', label: '处理状态', width: 80 },
        { id: 5, prop: 'point', label: '事故等级', width: 120 },
        { id: 9, prop: 'place', label: '事故发生地址', minWidth: 120 },
        { id: 10, prop: 'regulationsTime', label: '事故时间', width: 100 },
        { id: 3, prop: 'carSize', label: '驾驶员', width: 100 },
        { id: 3, prop: 'carSize', label: '处理结果', width: 100 }
      ],
      dataForm: {
        number: '',
        licensePlate: '',
        regulationsStatus: '',
        startTime: '',
        endTime: ''
      }
    }
  },

  methods: {
    query () {
      this.$refs.tableView.queryData()
    },

    refreshAll () {
      this.$http({
        url: this.$http.adornUrl('/car/break/regulations/syncAll'),
        method: 'post'
      }).then((data) => {
        this.successCallback(data)
      })
    },

    refresh (row) {
      this.$http({
        url: this.$http.adornUrl('/car/break/regulations/syncById'),
        method: 'post',
        data: row.id
      }).then((data) => {
        this.successCallback(data)
      })
    },

    successCallback (data) {
      if (data.code === 200) {
        this.query()
        this.$opts.success()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .el-form-item__content {
    .el-date-editor {
      display: inline-block;
      width: 140px;
    }
    label {
      margin: 10px;
    }
  }
</style>
